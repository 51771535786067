import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flower } from "../../types/Types";
import { createFlower, fetchFlowers, patchFlower } from "./flowersApi";
import clonedeep from "lodash.clonedeep";

export interface FlowersState {
  value: Flower[];
  status: "idle" | "loading" | "failed";
}

const initialState: FlowersState = {
  value: [],
  status: "idle",
};

export const getFlowersAsync = createAsyncThunk("flowers/fetchFlowers", async () => await fetchFlowers());
export const updateFlowerAsync = createAsyncThunk<Flower, Flower>(
  "flowers/patchFlowers",
  async (s) => await patchFlower(s)
);
export const createFlowerAsync = createAsyncThunk<Flower, Flower>(
  "flowers/postFlowers",
  async (s) => await createFlower(s)
);

export const flowersSlice = createSlice({
  name: "flowers",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getFlowersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFlowersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload;
      })
      .addCase(getFlowersAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateFlowerAsync.fulfilled, (state, action) => {
        const updatedState = clonedeep(state.value);
        const updateIndex = updatedState.findIndex((f) => f.id === action.payload.id);
        updatedState.splice(updateIndex, 1, action.payload);
        state.value = updatedState;
      })
      .addCase(createFlowerAsync.fulfilled, (state, action) => {
        state.value = [action.payload, ...state.value];
      });
  },
});

export default flowersSlice.reducer;
